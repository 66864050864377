import React, { useState } from 'react';

interface ToggleSwitchProps {
  isActive: boolean;
  onToggle: (newState: boolean) => void;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ isActive, onToggle }) => {
  const [isChecked, setIsChecked] = useState(isActive);

  const handleToggle = () => {
    const newState = !isChecked;
    setIsChecked(newState);
    onToggle(newState); // Вызываем функцию для обновления на сервере
  };

  return (
    <div className={`toggle-switch ${isChecked ? 'active' : ''}`} onClick={handleToggle}>
      <div className="toggle-knob"></div>
    </div>
  );
};

export default ToggleSwitch;

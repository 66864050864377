import React, { FC } from 'react';
import { TypeChat } from '../types/TypeChat';

interface ChatTypeSwitchProps {
  activeType: TypeChat;
  onTypeChange: (type: TypeChat) => void;
}

const ChatTypeSwitch: FC<ChatTypeSwitchProps> = ({ activeType, onTypeChange }) => {
  const chatTypes: ['group', 'supergroup', 'channel', 'private'] = ['group', 'supergroup', 'channel', 'private'];

  return (
    <div className="chat-type-switch">
      {chatTypes.map((type) => (
        <button
          key={type}
          className={activeType === type ? 'active' : ''}
          onClick={() => onTypeChange(type)}
        >
          {type.charAt(0).toUpperCase() + type.slice(1)}
        </button>
      ))}
    </div>
  );
};

export default ChatTypeSwitch;

import React, { useEffect, useState } from 'react';
import Input from '../components/Input';
import { getAllEnv, updateEnv } from '../api/env';
import Button from '../components/Button';
import Modal from '../components/Modal';

interface Env {
  key: string;
  value: string;
}

const Settings: React.FC = () => {
  const [envVars, setEnvVars] = useState({});
  const [newKey, setNewKey] = useState("");
  const [newValue, setNewValue] = useState("");
  const [modal, setModal] = useState<'save' | 'create' | null>(null);

  const loadEnv = async () => {
    const envObj = await getAllEnv();

    setEnvVars(envObj);
  }

  const handleChange = (key: string, value: string) => {
    setEnvVars({ ...envVars, [key]: value });
  };

  const handleSave = async () => {
    await updateEnv(JSON.stringify(envVars));
    await loadEnv();
    setModal(null);
  };

  const handleAdd = () => {
    if (newKey && newValue) {
        setEnvVars({ ...envVars, [newKey]: newValue });
        setNewKey("");
        setNewValue("");
        setModal(null);
    }
  };

  useEffect(() => {
    loadEnv();
  }, []);

  return (
    <div>
      <div className="env widget-container">
        <div className='env__header'>
          <h2 className='env__section-title'>Env</h2>

          <div className='env__menu'>
            <Button text='Creat New' onClick={() => setModal('create')} />

            <Button
              text='Save'
              onClick={() => setModal('save')}
              color='red'
            />
          </div>
        </div>

        <ul className='env__list'>
          {Object.entries(envVars).map(([key, value]: any) => (
            <li className='env__items' key={key}>
              <h3 className='env__key'>{key}</h3>
              <Input
                value={value}
                onChange={(e) => handleChange(key, e.target.value)}
              />
            </li>
          ))}
        </ul>

        <Modal
          isOpen={modal === 'save'}
          onClose={() => setModal(null)}
          title="You sure?"
        >
          <div className='env__modal-buttons'>
            <Button text='Cancel' onClick={() => setModal(null)} />

            <Button
              text='Save'
              onClick={handleSave}
              color='red'
            />
          </div>
        </Modal>

        <Modal
          isOpen={modal === 'create'}
          onClose={() => setModal(null)}
          title="Create New"
        >
          <div className='env__modal-content'>
            <div className='env__modal-inputs'>
              <Input
                value={newKey}
                onChange={(e) => setNewKey(e.target.value)}
                placeholder='KEY'
              />

              <Input
                value={newValue}
                onChange={(e) => setNewValue(e.target.value)}
                placeholder='VALUE'
              />
            </div>

            <div className='env__modal-buttons'>
              <Button
                text='Cancel'
                onClick={() => setModal(null)}
                color='red'
              />

              <Button
                text='Save'
                onClick={handleAdd}
              />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  )
};

export default Settings;

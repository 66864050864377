import React from "react";
// import { Widget } from "../Widget/Widget";
import classNames from "classnames";

interface Props {
  page: number;
  countPages: number;
  pageChange: (page: number) => void;
}

export const Pagination: React.FC<Props> = ({ page, countPages, pageChange }) => {
  const pages = Array.from({ length: countPages }, (_, index) => index + 1);

  return (
    <div  className="pagination-widget">
      <ul className="pagination">
        {pages.map(pageNum => (
          <li key={pageNum} className="pagination__item">
            <button
              type="button"
              className={classNames('pagination__button', {
                 'pagination__button--active': pageNum === page,
              })}
              onClick={() => pageChange(pageNum)}
            >
              {pageNum}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

import React, { FC } from 'react';
import classNames from 'classnames';

interface ButtonProps {
  type?: 'button' | 'submit';
  text: string;
  onClick?: () => void;
  color?: 'default' | 'red'
  // className?: string;
}

const Button: FC<ButtonProps> = ({ type = 'button', text, onClick, color /* className */ }) => {
  return (
    <button
      type={type}
      className={classNames(
        'button',
        {
          'button__red': color === 'red',
          // [String(className)]: !!className,
        },
      )}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default Button;

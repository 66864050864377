import React from 'react';
import classNames from 'classnames';

interface InputProps {
  type?: string;
  placeholder?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  withTagIcon?: boolean; // добавим пропс для отображения @
}

const Input: React.FC<InputProps> = ({ 
  type = 'text', 
  placeholder, 
  value, 
  onChange, 
  className, 
  withTagIcon = false 
}) => {
  return (
    <div className={classNames('input-wrapper', className, { 'input-with-tag': withTagIcon })}>
      {withTagIcon && <span className="input-tag-icon">@</span>}
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className="input-field"
      />
    </div>
  );
};

export default Input;

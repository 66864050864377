import { getQuipsByTrigerDataId } from "../api/quip";
import { Column } from "../types/Column";

export const quipColumns: Column[] = [
  {
    id: 1,
    name: 'ID',
    key: 'id',
    type: 'string', // Обычное текстовое значение
  },
  {
    id: 2,
    name: 'Quips',
    key: 'quips',
    'second-key': 'text',
    type: 'array', // Массив, нужен дополнительный рендер
    // getList: async (id) => {
    //   return await getQuipsByTrigerDataId(id);
    // }
  },
  {
    id: 3,
    name: 'Keywords',
    key: 'keywords',
    'second-key': 'text',
    type: 'array', // Массив, нужен дополнительный рендер
  },
  {
    id: 4,
    name: 'Min Duplicate Count',
    key: 'minDuplicateCount',
    type: 'string', // Обычное текстовое значение
  },
];

import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createQuip, getQuips, removeQuip, updateQuip } from '../../../api/quip';
import { Quip } from '../../../types/Quip';

type Status = 'idle' | 'loading' | 'succeeded' | 'failed';

interface QuipState {
  quips: Quip[];
  status: Status;
  error: string | null;
  page: number;
  pageSize: number;
  count: number;
  searchTerm: string;
}

const initialState: QuipState = {
  quips: [],
  status: 'idle',
  error: null,
  page: 1,
  pageSize: 20,
  count: 0,
  searchTerm: '',
};


export const fetchKeyAnswers = createAsyncThunk('keyAnswer/fetchKeyAnswers', async (params: { searchTerm: string, page: number, pageSize: number }) => {
  return await getQuips(params);
});

export const addKeyAnswer = createAsyncThunk('keyAnswer/addKeyAnswer', async (createdObject: Quip) => {
  return await createQuip(createdObject);
});

export const editKeyAnswer = createAsyncThunk('keyAnswer/editKeyAnswer', async (updatedObject: Quip) => {
  return await updateQuip(updatedObject);
});

export const deleteKeyAnswer = createAsyncThunk('keyAnswer/deleteKeyAnswer', async (id: number) => {
  await removeQuip(id);
  return id;
});

const quipSlice = createSlice({
  name: 'quip',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchKeyAnswers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchKeyAnswers.fulfilled, (state, action: PayloadAction<{ rows: Quip[], count: number, searchTerm: string }>) => {
        state.status = 'succeeded';
        state.quips = action.payload.rows;
        state.count = action.payload.count;
      })
      .addCase(fetchKeyAnswers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch data';
      })
      .addCase(addKeyAnswer.fulfilled, (state, action: PayloadAction<Quip>) => {
        state.quips.unshift(action.payload);
      })
      .addCase(editKeyAnswer.fulfilled, (state, action: PayloadAction<Quip>) => {
        const index = state.quips.findIndex(quip => quip.id === action.payload.id);
        if (index !== -1) {
          state.quips[index] = action.payload;
        }
      })
      .addCase(deleteKeyAnswer.fulfilled, (state, action: PayloadAction<number>) => {
        state.quips = state.quips.filter(quip => quip.id !== action.payload);
      });
  },
});

export default quipSlice.reducer;
export const { actions } = quipSlice;

import React, { useState, useEffect } from 'react';
import ChatTypeSwitch from '../components/ChatTypeSwitch';
import TableView from '../components/TableView';
import { getChatsByType } from '../api/chats';
import { TypeChat } from '../types/TypeChat';
import { chatColumns } from '../constants/chatColumns';
import { privateChatColumns } from '../constants/privateChatColumns';

const Chats = () => {
  const [activeType, setActiveType] = useState<TypeChat>('group');
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchChatsByType(activeType);
  }, [activeType]);

  const fetchChatsByType = async (type: TypeChat) => {
    setLoading(true);
    setError('');

    try {
      const data = await getChatsByType(type);
      setChats(data);
    } catch (error) {
      setError('Failed to fetch chats');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="widget-container">
        <ChatTypeSwitch activeType={activeType} onTypeChange={setActiveType} />
      </div>

      <div className="data-table-container">
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>{error}</div>
        ) : activeType === 'private' ? (
          <TableView columns={privateChatColumns} data={chats} />
        ) : (
          <TableView columns={chatColumns} data={chats} />
        )}
      </div>
    </div>
  );
};

export default Chats;

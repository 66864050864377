import React, { FC } from 'react';
import classNames from 'classnames';

interface TextareaProps {
  placeholder: string;
  className?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const Textarea: FC<TextareaProps> = ({ placeholder, className, value, onChange }) => {
  return (
    <textarea
      className={classNames('textarea', className)}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
};

export default Textarea;

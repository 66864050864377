import React, { useState, useEffect, FormEvent, useCallback } from 'react';
import TableView from '../components/TableView';
import ControlWidget from '../components/ControlWidget';
import Modal from '../components/Modal';
import Textarea from '../components/Textarea';
import Button from '../components/Button';
import { quipColumns } from '../constants/quipColumns';
import { importQuips } from '../api/quip';
import ImportModal from '../components/ImportModal';
import { Pagination } from '../components/Pagination';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { actions, fetchKeyAnswers } from '../redux/features/quip/quipSlice';

const Quips = () => {
  const [newQuip, setNewQuip] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalImport, setIsModalImport] = useState(false);

  const dispatch = useAppDispatch();
  const { quips, page, pageSize, count, status, searchTerm } = useAppSelector(state => state.keyAnswer);

  useEffect(() => {
    dispatch(fetchKeyAnswers({ searchTerm, page, pageSize }))
  }, [page, searchTerm, pageSize, dispatch]);

  const handleAddQuip = () => {
    setModalOpen(true);
  };

  const handleSaveQuip = async () => {
    // await createJoke(newQuip);
    // setModalOpen(false);
    // setNewQuip('');
    // fetchQuips(); // Обновляем список шуток после создания
  };

  const pageChange = useCallback((page: number) => {
    dispatch(actions.setPage(page));
  }, [dispatch]);

  return (
    <div>
      <div className="widget-container">
        <ControlWidget
          onAddClick={handleAddQuip}
          addButtonLabel="Add Joke"
          showFilter={true}
          showImport={true}
          onImportClick={() => {
            setIsModalImport(true)
          }}
          
        />
      </div>

      <div className="widget-container">
        {status === 'loading' ? (
          <div>Loading...</div>
        ) : status === 'failed' ? (
          <div>Loading failed.</div>
        ) : (
          <TableView columns={quipColumns} data={quips} />
        )}
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        title="Add New Joke"
      >
        <Textarea
          placeholder="Enter your joke here..."
          value={newQuip}
          onChange={(e) => setNewQuip(e.target.value)}
        />
        <Button text="Save" onClick={handleSaveQuip} />
      </Modal>

      <ImportModal
        isModalOpen={isModalImport}
        onClose={() => setIsModalImport(false)}
        onImport={importQuips}
      />

      <Pagination page={page} countPages={Math.ceil(count / pageSize)} pageChange={pageChange} />
    </div>
  );
};

export default Quips;

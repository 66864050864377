import { TypeChat } from '../types/TypeChat';
import { client } from './client';

// Получение всех чатов
export const getChats = async () => {
  const response = await client.get('/chats');
  console.log(response.data);
  return response.data;
};

// Получение чатов по типу
export const getChatsByType = async (type: TypeChat) => {
  const response = await client.get(`/chats/type/${type}`);
  console.log(response.data);
  return response.data;
};

// Обновление статуса бота в чате
export const updateChatStatus = async (chatId: number, newStatus: boolean) => {
  const response = await client.put(`/chats/${chatId}/status`, { is_bot_active: newStatus });
  console.log(response.data);
  return response.data;
};

import React, { ChangeEvent, FormEvent, useState } from 'react';
import Modal from './Modal';
import Button from './Button';

interface Props {
  isModalOpen: boolean;
  onClose: () => void;
  onImport: (file: File) => void;
}

const ImportModal: React.FC<Props> = ({ isModalOpen, onClose, onImport }) => {
  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (!files) {
      return;
    }

    await setFile(files[0]);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (file) {
      onImport(file);
      onClose();
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={() => onClose()}
      title="Import Data"
    >
      <form onSubmit={handleSubmit}>
        <input type="file" accept=".csv" onChange={handleFileChange} />
        <Button type='submit' text="Import" />
      </form>
    </Modal>
  );
};

export default ImportModal;
